// src/components/HideNavBar.js
import { useEffect } from 'react';

function HideNavBar() {
  useEffect(() => {
    const hideNavBar = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY === 0) {
          window.scrollTo(0, 1);
        }
      }
    };

    // Exécuter immédiatement
    hideNavBar();

    // Réessayer après un court délai pour s'assurer que la page est complètement chargée
    const timeoutId = setTimeout(hideNavBar, 300);

    // Ajouter des écouteurs d'événements pour maintenir la barre cachée
    window.addEventListener('resize', hideNavBar);
    window.addEventListener('orientationchange', hideNavBar);

    // Nettoyage
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', hideNavBar);
      window.removeEventListener('orientationchange', hideNavBar);
    };
  }, []);

  return null;
}

export default HideNavBar;