import { lazy } from 'react';

export const SITE_CONFIG = {
  isLaunched: false,
  secretKey: 'poneysecret2024'
};

export const HOME_SECTIONS_SEO = {
  title: 'Accueil',
  description: 'Poney Club Toulouse - Le meilleur de la musique live à Toulouse. Concerts, événements et soirées exceptionnels.',
  keywords: 'club musique, concerts toulouse, soirées toulouse, live music, événements musicaux',
  sections: [
    { id: 'hero', title: 'À la une' },
    { id: 'events', title: 'Événements' },
    { id: 'artistes', title: 'Artistes' },
    { id: 'merch', title: 'Boutique' },
    { id: 'food', title: 'Food & Drinks' },
    { id: 'about', title: 'À propos' },
    { id: 'partenaires', title: 'Partenaires' }
  ]
};

export const ROUTES_CONFIG = [
  {
    path: '/agenda',
    component: lazy(() => import('../pages/Agenda/AgendaPage')),
    title: 'Agenda',
    description: 'Découvrez tous nos événements - Concerts et soirées au Poney Club Toulouse'
  },
  {
    path: '/foodanddrinks',
    component: lazy(() => import('../pages/Food/FoodPage')),
    title: 'Food & Drinks',
    description: 'Découvrez notre carte de restauration et notre sélection de boissons'
  },
  {
    path: '/ticket',
    component: lazy(() => import('../pages/Ticket/TicketPage')),
    title: 'Billeterie',
    description: 'Réservez vos billets pour les événements au Poney Club Toulouse'
  },
  {
    path: '/agenda/:slug',
    component: lazy(() => import('../pages/SingleEvent/SingleEvent')),
    title: 'Événement',
    description: 'Détails de l\'événement au Poney Club Toulouse'
  },
  {
    path: '/artistes/:slug',
    component: lazy(() => import('../components/SingleArtiste')),
    title: 'Artiste',
    description: 'Découvrez l\'artiste et ses performances au Poney Club Toulouse'
  },
  {
    path: '/merchandising',
    component: lazy(() => import('../pages/Merch/MerchandisePage')),
    title: 'Boutique',
    description: 'Découvrez notre collection de merchandising officiel'
  }
];