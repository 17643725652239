
import axios from 'axios';
import { CacheManager } from './cacheManager';
import { ArtistesService } from './services/artistesService';
import { EventsService } from './services/eventsService';
import { MediaService } from './services/mediaService';

class WordPressAPI {
  constructor() {
    this.baseURL = 'https://www.poneyclubtoulouse.fr/backend';
    
    // Création des instances axios
    this.api = axios.create({
      baseURL: `${this.baseURL}/wp-json/wp/v2`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      timeout: 15000
    });

    this.optionsApi = axios.create({
      baseURL: `${this.baseURL}/wp-json/acf/v3`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      timeout: 15000
    });

    // Services
    this.cacheManager = new CacheManager();
    this.mediaService = new MediaService(this.api, this.cacheManager, this.baseURL);
    this.artistesService = new ArtistesService(this.api, this.cacheManager);
    this.eventsService = new EventsService(this.api, this.cacheManager, this.artistesService);

    // Bind des méthodes
    this.bindMethods();
  }

  bindMethods() {
    // Méthodes principales
    this.getOptions = this.getOptions.bind(this);
    this.getSiteOptions = this.getSiteOptions.bind(this);

    // Méthodes des services avec gestion d'erreur
    this.getEventements = async () => {
      try {
        return await this.eventsService.getAll();
      } catch (error) {
        console.error('Error in getEventements:', error);
        return [];
      }
    };

    this.getEventement = async (slug) => {
      try {
        return await this.eventsService.getBySlug(slug);
      } catch (error) {
        console.error('Error in getEventement:', error);
        return null;
      }
    };

    this.getArtisteBySlug = async (slug) => {
      try {
        return await this.artistesService.getBySlug(slug);
      } catch (error) {
        console.error('Error in getArtisteBySlug:', error);
        return null;
      }
    };

    // IMPORTANT : Exposer fetchMedia
    this.fetchMedia = async (mediaId) => {
      try {
        if (!mediaId) return null;
        return await this.mediaService.fetchMedia(mediaId);
      } catch (error) {
        console.error('Error in fetchMedia:', error);
        return null;
      }
    };

    this.getFoods = async () => {
      try {
        return await this.mediaService.getFoods();
      } catch (error) {
        console.error('Error in getFoods:', error);
        return [];
      }
    };

    this.getPartenaires = async () => {
      try {
        return await this.mediaService.getPartenaires();
      } catch (error) {
        console.error('Error in getPartenaires:', error);
        return [];
      }
    };

    this.getMerchandising = async () => {
      try {
        return await this.mediaService.getMerchandising();
      } catch (error) {
        console.error('Error in getMerchandising:', error);
        return [];
      }
    };
  }

  async getOptions() {
    try {
      const response = await this.optionsApi.get('/options');
      return response.data || {};
    } catch (error) {
      console.error('Error in getOptions:', error);
      return {};
    }
  }

  async getSiteOptions() {
    try {
      return await this.getOptions();
    } catch (error) {
      console.error('Error in getSiteOptions:', error);
      return {};
    }
  }
}

// Instance unique
const wordpressAPI = new WordPressAPI();
export default wordpressAPI;