import axios from 'axios';

export const API_CONFIG = {
  // Configuration des priorités de chargement
  PRIORITIES: {
    CRITICAL: 1,
    HIGH: 2,
    NORMAL: 3,
    LOW: 4
  },

  // Configuration des ressources avec priorités
  RESOURCES: {
    ARTISTES: {
      endpoint: '/wp-json/wp/v2/artiste',
      priority: 1,
      preload: true,
      batchSize: 25,
      ttl: 900000,
      fields: 'id,title,slug,acf'
    },
    EVENTS: {
      endpoint: '/wp-json/wp/v2/event',
      priority: 1,
      preload: true,
      batchSize: 25,
      ttl: 900000,
      fields: 'id,title,slug,acf'
    },
    OPTIONS: {
      endpoint: '/wp-json/acf/v3/options',
      priority: 2,
      preload: true,
      ttl: 3600000
    },
    MEDIA: {
      endpoint: '/wp-json/wp/v2/media',
      priority: 2,
      preload: false,
      ttl: 3600000
    },
    FOOD: {
      endpoint: '/wp-json/wp/v2/food',
      priority: 3,
      preload: false,
      ttl: 3600000
    },
    MERCHANDISING: {
      endpoint: '/wp-json/wp/v2/merchandising',
      priority: 3,
      preload: false,
      ttl: 3600000
    },
    PARTENAIRES: {
      endpoint: '/wp-json/wp/v2/partenaire',
      priority: 4,
      preload: false,
      ttl: 3600000
    }
  },

  TIMEOUTS: {
    CRITICAL: 5000,
    STANDARD: 8000,
    LONG: 15000
  },

  ENDPOINTS: {
    WORDPRESS: '/wp-json/wp/v2',
    OPTIONS: '/wp-json/acf/v3/options',
    CUSTOM: '/wp-json/custom/v1',
    MEDIA: '/wp-json/wp/v2/media'
  },

  PAGINATION: {
    DEFAULT_PER_PAGE: 100,
    MAX_PER_PAGE: 100,
    MIN_PER_PAGE: 10
  },

  CACHE: {
    STRATEGIES: {
      CRITICAL: {
        maxAge: 900000,
        staleWhileRevalidate: true,
        priority: 1
      },
      STANDARD: {
        maxAge: 300000,
        staleWhileRevalidate: false,
        priority: 2
      }
    },
    MAX_MEMORY_ITEMS: 1000,
    MEMORY_CLEANUP_FACTOR: 0.2,
    TYPES: {
      MEDIA: 'media',
      ARTISTE: 'artiste',
      EVENT: 'event',
      GENERAL: 'general',
      FOOD: 'food',
      MERCH: 'merchandising',
      PARTNER: 'partenaire'
    }
  },

  MEDIA: {
    ALLOWED_TYPES: ['image/jpeg', 'image/png', 'image/webp', 'image/gif'],
    MAX_FILE_SIZE: 5242880,
    IMAGE_SIZES: {
      THUMBNAIL: 'thumbnail',
      MEDIUM: 'medium',
      LARGE: 'large',
      FULL: 'full'
    }
  },

  ERRORS: {
    NETWORK: {
      TIMEOUT: 'La requête a expiré',
      NOT_FOUND: 'Ressource non trouvée',
      SERVER_ERROR: 'Erreur serveur',
      UNAUTHORIZED: 'Non autorisé',
      FORBIDDEN: 'Accès refusé'
    },
    DATA: {
      INVALID: 'Données invalides',
      MISSING: 'Données manquantes',
      MALFORMED: 'Format de données incorrect'
    },
    CACHE: {
      SET_ERROR: 'Erreur lors de la mise en cache',
      GET_ERROR: 'Erreur lors de la récupération du cache'
    }
  },

  STATUS: {
    PUBLISHED: 'publish',
    DRAFT: 'draft',
    PRIVATE: 'private',
    PENDING: 'pending'
  },

  REQUEST: {
    RETRIES: 3,
    RETRY_DELAY: 1000,
    HEADERS: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  },

  ENV: {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    STAGING: 'staging'
  }
};

class PriorityRequestManager {
  constructor(config) {
    this.config = config;
    this.queue = new Map();
    this.processing = false;
    this.preloadedData = new Map();
  }

  addRequest(priority, request, key = null) {
    // Vérifier si les données sont préchargées
    if (key && this.preloadedData.has(key)) {
      return Promise.resolve(this.preloadedData.get(key));
    }

    if (!this.queue.has(priority)) {
      this.queue.set(priority, []);
    }
    const promise = new Promise((resolve, reject) => {
      this.queue.get(priority).push({ request, resolve, reject });
    });
    this.processQueue();
    return promise;
  }

  async processQueue() {
    if (this.processing) return;
    this.processing = true;

    const priorities = [...this.queue.keys()].sort();
    
    for (const priority of priorities) {
      const requests = this.queue.get(priority);
      await Promise.allSettled(
        requests.map(async ({ request, resolve, reject }) => {
          try {
            const result = await request();
            resolve(result);
          } catch (error) {
            reject(error);
          }
        })
      );
      this.queue.delete(priority);
    }

    this.processing = false;
  }

  setPreloadedData(key, data) {
    this.preloadedData.set(key, data);
  }

  clearPreloadedData() {
    this.preloadedData.clear();
  }
}

export const createOptimizedAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL,
    timeout: API_CONFIG.TIMEOUTS.STANDARD,
    headers: {
      ...API_CONFIG.REQUEST.HEADERS,
      'X-Priority': 'normal'
    }
  });

  const requestManager = new PriorityRequestManager(API_CONFIG);

  instance.interceptors.request.use(config => {
    const resourcePath = config.url.split('?')[0];
    const resourceConfig = Object.values(API_CONFIG.RESOURCES)
      .find(r => resourcePath.includes(r.endpoint));

    if (resourceConfig) {
      config.timeout = resourceConfig.priority === 1 ? 
        API_CONFIG.TIMEOUTS.CRITICAL : 
        API_CONFIG.TIMEOUTS.STANDARD;

      config.headers['X-Priority'] = 
        resourceConfig.priority === 1 ? 'critical' : 'normal';
    }

    return config;
  });

  return { instance, requestManager };
};

export const preloadCriticalResources = async (axiosInstance) => {
  const criticalResources = Object.entries(API_CONFIG.RESOURCES)
    .filter(([_, config]) => config.preload && config.priority === 1);

  return Promise.all(
    criticalResources.map(async ([key, config]) => {
      try {
        const response = await axiosInstance.get(config.endpoint, {
          params: {
            per_page: config.batchSize,
            _fields: config.fields
          }
        });
        return { key, data: response.data };
      } catch (error) {
        console.error(`Error preloading ${key}:`, error);
        return { key, data: null };
      }
    })
  );
};

export const processImageUrl = (baseURL, url) => {
  if (!url) return null;
  if (url.startsWith('http')) return url;
  return `${baseURL}/wp-content/uploads/${url}`;
};

export const validateConfig = {
  isValidId: (id) => typeof id === 'number' && id > 0,
  isValidSlug: (slug) => typeof slug === 'string' && slug.length > 0,
  isValidUrl: (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  },
  isValidImageType: (type) => API_CONFIG.MEDIA.ALLOWED_TYPES.includes(type),
  isValidStatus: (status) => Object.values(API_CONFIG.STATUS).includes(status)
};

export const formatUtils = {
  cleanSlug: (slug) => slug.toLowerCase().replace(/[^a-z0-9-]/g, '-'),
  formatDate: (date) => new Date(date).toISOString(),
  truncateString: (str, length = 100) => 
    str.length > length ? `${str.substring(0, length)}...` : str,
  stripHtml: (html) => html.replace(/<[^>]*>/g, '')
};

export const getEnvironmentConfig = () => {
  const env = process.env.NODE_ENV || 'development';
  return {
    development: {
      baseURL: 'https://www.poneyclubtoulouse.fr/backend',
      debug: true,
      cache: true
    },
    production: {
      baseURL: 'https://www.poneyclubtoulouse.fr/backend',
      debug: false,
      cache: true
    },
    staging: {
      baseURL: 'https://staging.poneyclubtoulouse.fr/backend',
      debug: true,
      cache: true
    }
  }[env] || 'development';
};

export const ENV_CONFIG = getEnvironmentConfig();

export const createFullUrl = (path) => {
  const baseUrl = ENV_CONFIG.baseURL.replace(/\/$/, '');
  const cleanPath = path.replace(/^\//, '');
  return `${baseUrl}/${cleanPath}`;
};

export const handleApiError = (error) => {
  if (error.response) {
    const status = error.response.status;
    if (status === 404) return new Error(API_CONFIG.ERRORS.NETWORK.NOT_FOUND);
    if (status === 401) return new Error(API_CONFIG.ERRORS.NETWORK.UNAUTHORIZED);
    if (status === 403) return new Error(API_CONFIG.ERRORS.NETWORK.FORBIDDEN);
    if (status >= 500) return new Error(API_CONFIG.ERRORS.NETWORK.SERVER_ERROR);
  }
  
  if (error.code === 'ECONNABORTED') {
    return new Error(API_CONFIG.ERRORS.NETWORK.TIMEOUT);
  }
  
  return error;
};

export default {
  API_CONFIG,
  ENV_CONFIG,
  createOptimizedAxiosInstance,
  preloadCriticalResources,
  processImageUrl,
  validateConfig,
  formatUtils,
  createFullUrl,
  handleApiError
};