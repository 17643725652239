// src/components/ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTopSmooth = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTopSmooth);
        window.scrollTo(0, c - c / 8);
      }
    };

    // Utiliser un petit délai pour éviter les problèmes potentiels avec certains navigateurs
    setTimeout(() => {
      scrollToTopSmooth();
    }, 100);
  }, [pathname]);

  return null;
}

export default ScrollToTop;