import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HomeIcon, CalendarIcon, UtensilsIcon, ShoppingBagIcon } from 'lucide-react';

const BottomNavigation = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Considère mobile si largeur <= 768px
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const menuItems = [
    { path: '/', icon: <HomeIcon size={30} /> },
    { path: '/agenda', icon: <CalendarIcon size={30} />},
    { path: '/foodanddrinks', icon: <UtensilsIcon size={30} /> },
    { path: '/merchandising', icon: <ShoppingBagIcon size={30} /> }
  ];

  if (!isMobile) return null; // Ne rend rien si ce n'est pas mobile

  return (
    <nav style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderTop: '1px solid #e0e0e0',
      padding: '10px 0',
      zIndex: 1000
    }}>
      {menuItems.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          style={({ isActive }) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: isActive ? '#000' : '#666',
            textDecoration: 'none',
            padding: '5px 10px'
          })}
        >
          {item.icon}
          <span style={{ fontSize: '12px', marginTop: '5px' }}>{item.label}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default BottomNavigation;